import React from 'react';
import * as styles from './contact.module.css';

import Container from '../components/Container';
import Contact from '../components/Contact';
import Layout from '../components/Layout';

const ContactPage = () => {
  return (
    <Layout>
      <Container>
        <Contact></Contact>
      </Container>
    </Layout>
  );
};

export default ContactPage;
